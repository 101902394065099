import './style.scss';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/pagination';

const initSwiper = () => {
	const slider = document.querySelectorAll('.l23b-entry-slider-list');

	let spaceBetween = 16;

	/* if (slider.classList.contains('slider--black')) {
		spaceBetween = 0;
	} */

	slider.forEach(elem => {

		const slidesPerView = elem.dataset.numSp ?? '1';
		const slidesPerViewTab = elem.dataset.numTablet ?? '2';
		const slidesPerViewPc = elem.dataset.numPc ?? '3';
		const slidesPerViewWide = elem.dataset.numWide ?? '3';

		new Swiper(elem, {
			// configure Swiper to use modules
			modules: [Navigation, Pagination],
			pagination: {
				el: '.l23b-entry-slider-list__nav',
			},
			navigation: {
				nextEl: '.l23b-entry-slider-list__button--next',
				prevEl: '.l23b-entry-slider-list__button--prev',
			},
			slidesPerView: Number(slidesPerView),
			spaceBetween: spaceBetween,
			clickable: true,
			breakpoints: {
				768: {
					slidesPerView: Number(slidesPerViewTab),
				},
				920: {
					slidesPerView: Number(slidesPerViewPc),
				},
				1400: {
					slidesPerView: Number(slidesPerViewWide),
				},
			}
		});
	})
	
}
initSwiper();