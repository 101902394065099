import { slideToggle } from '../../utils/slideToggle';
import './style.scss';

const l23bToggleAccordion = () => {
    const targets = document.querySelectorAll('.l23b-faq-list--accordion .l23b-faq__q');

    targets.forEach(elem => {
        elem.addEventListener('click', () => {

            const next = elem.nextSibling;

            slideToggle(next, 300);
            elem.classList.toggle('is-open');
        })
    })
}
l23bToggleAccordion();